<template>
  <div class="flex justify-center" v-if="selectedContainer">
    <OrderCalculation @onSubmit="onSubmit" />
    <CModal
      v-model="showErrorModal"
      custom-footer
    >
      <template v-slot:header>
        <template>
          {{ $t('orderError.containerAlreayBooked') }}
        </template>
      </template>
      
      <CMessage variant="danger">
        {{ $t('orderError.containerAlreayBookedDesc') }}
      </CMessage>

      <template v-slot:footer>
        <CButton variant="primary" :to="backLink">
          {{ $t('changeOrderData') }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  SET_DELIVERY_DATE,
  SET_NEXT_ROUTE,
  SET_PICKUP_DATE,
  SET_SELECTED_CONTAINER,
  SET_SELECTED_DELIVERY_AREA,
  SET_SELECTED_MERCHANT_WASTE,
  SET_DELIVERY_ADDRESS,
  SET_BILLDING_ADDRESS,
  SET_USE_LAST_CANCELED_ORDER,
  SET_ANONYMOUS_ORDER,
} from '@/store/mutationTypes';
import {
  ANONYMOUS_ORDER,
  CUSTOMER_NOTE,
  DELIVERY_DATE,
  LAST_CANCELED_ORDER,
  MATCHTING_CONTAINERS,
  PICKUP_DATE,
  SELECTED_BILLING_ADDRESS,
  SELECTED_CONTAINER,
  SELECTED_DELIVERY_ADDRESS,
  SELECTED_DELIVERY_AREA,
  SELECTED_MERCHANT_WASTE,
  THIS_USER,
  USE_LAST_CANCELED_ORDER,
} from '@/store/gettersTypes';
import { OrderCalculation } from '@/ui/components';
import { CREATE_ORDER, GET_CONTAINER_SELECTION } from '@/store/actionTypes';

export default {
  components: {
    OrderCalculation,
  },

  data() {
    return {
      showErrorModal: false,
    };
  },

  computed: {
    ...mapGetters([
      SELECTED_MERCHANT_WASTE,
      SELECTED_DELIVERY_AREA,
      SELECTED_CONTAINER,
      DELIVERY_DATE,
      PICKUP_DATE,
      MATCHTING_CONTAINERS,
      SELECTED_DELIVERY_ADDRESS,
      SELECTED_BILLING_ADDRESS,
      USE_LAST_CANCELED_ORDER,
      LAST_CANCELED_ORDER,
      CUSTOMER_NOTE,
      ANONYMOUS_ORDER,
      THIS_USER,
    ]),
    backLink() {
      // eslint-disable-next-line max-len
      return `/select-container?merchantWasteId=${this.selectedMerchantWaste.id}&deliveryAreaId=${this.selectedDeliveryArea.id}`;
    },
  },

  created() {
    if (this.useLastCanceledOrder && this.lastCanceledOrder) {
      this.$router.push({
        path: this.$route.path,
        query: {
          merchantWasteId: this.lastCanceledOrder.containerOrder.merchantWasteId,
          deliveryAreaId: this.lastCanceledOrder.deliveryAreaId,
          productId: this.lastCanceledOrder.containerOrder.productId,
          deliveryDate: this.lastCanceledOrder.containerOrder.deliveryDate,
          pickupDate: this.lastCanceledOrder.containerOrder.pickupDate,
          deliveryAddressId: this.lastCanceledOrder.deliveryAddressId,
          billingAddressId: this.lastCanceledOrder.invoiceAddressId,
        },
      });
    }

    setTimeout(async () => {
      if (
        this.$route.query &&
        this.$route.query.merchantWasteId &&
        parseInt(this.$route.query.merchantWasteId, 10) &&
        this.$route.query.deliveryAreaId &&
        parseInt(this.$route.query.deliveryAreaId, 10) &&
        this.$route.query.productId &&
        parseInt(this.$route.query.productId, 10) &&
        this.$route.query.deliveryDate &&
        this.$DateTime.fromJSDate(new Date(this.$route.query.deliveryDate)).isValid &&
        this.$route.query.pickupDate &&
        this.$DateTime.fromJSDate(new Date(this.$route.query.pickupDate)).isValid
      ) {
        this.setSelectedMerchantWaste(parseInt(this.$route.query.merchantWasteId, 10));
        this.setSelectedDeliveryArea(parseInt(this.$route.query.deliveryAreaId, 10));
        this.setSelectedContainer(parseInt(this.$route.query.productId, 10));
        this.setDeliveryDate(new Date(this.$route.query.deliveryDate));
        this.setPickupDate(new Date(this.$route.query.pickupDate));
      }

      if (
        this.$route.query.deliveryAddressId &&
        parseInt(this.$route.query.deliveryAddressId, 10)
      ) {
        this.setDeliveryAddress(parseInt(this.$route.query.deliveryAddressId, 10));

        if (this.$route.billingAddressId) {
          this.setBillingAddress(parseInt(this.$route.query.billingAddressId, 10));
        }
      }

      if (this.$route.query.anonymousOrder) {
        const anonymousOrder = JSON.parse(this.$route.query.anonymousOrder);
        this.setAnonymousOrder({
          ...this.anonymousOrder,
          ...anonymousOrder,
        });
      }

      if (!this.matchingContainers.length) {
        await this.getContainerSelection();
      }

      if (
        !this.selectedMerchantWaste ||
        !this.selectedDeliveryArea ||
        !this.selectedContainer ||
        !this.deliveryDate ||
        !this.pickupDate ||
        (this.thisUser && !this.selectedDeliveryAddress) ||
        (!this.thisUser &&
          (!this.anonymousOrder.user ||
            !this.anonymousOrder.billingAddress ||
            !this.anonymousOrder.deliveryAddress))
      ) {
        this.$router.push('/');
      }
    }, 10);
  },

  methods: {
    ...mapMutations([
      SET_SELECTED_MERCHANT_WASTE,
      SET_SELECTED_DELIVERY_AREA,
      SET_SELECTED_CONTAINER,
      SET_DELIVERY_DATE,
      SET_PICKUP_DATE,
      SET_NEXT_ROUTE,
      SET_DELIVERY_ADDRESS,
      SET_BILLDING_ADDRESS,
      SET_ANONYMOUS_ORDER,
    ]),
    ...mapActions([GET_CONTAINER_SELECTION, CREATE_ORDER, SET_USE_LAST_CANCELED_ORDER]),
    async onSubmit() {
      try {
        let data;
        if (this.thisUser) {
          data = await this.createOder({
            productId: this.selectedContainer.id,
            merchantWasteId: this.selectedMerchantWaste.id,
            deliveryAddressId: this.selectedDeliveryAddress,
            billingAddressId: this.selectedBillingAddress,
            deliveryDate: this.deliveryDate,
            pickupDate: this.pickupDate,
            customerNote: this.customerNote,
          });
        } else {
          data = await this.createOder({
            productId: this.selectedContainer.id,
            merchantWasteId: this.selectedMerchantWaste.id,
            deliveryAddress: this.anonymousOrder.deliveryAddress,
            billingAddress: this.anonymousOrder.billingAddress,
            deliveryDate: this.deliveryDate,
            pickupDate: this.pickupDate,
            customerNote: this.customerNote,
            user: this.anonymousOrder.user,
          });
        }

        window.location.href = data.redirectUrl;
      } catch (err) {
        if (err.response.data.errorMsg === 'CONTAINER_OVERBOOKED') {
          this.showErrorModal = true;
        } else {
          console.log(err);
        }
      }
    },
  },
};
</script>
